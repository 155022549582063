import { BOOKING_TYPE } from '../../views/Bookings/types';

export const bookingCardColors = ['#FBE1C5', '#B7DADB', '#C4F1E0', '#F5C382', '#B5D1F1'];

export enum CALENDAR_MODAL_TITLES {
  NEW_BOOKING = 'New event',
  BLOCK_SLOT = 'Block a slot'
}

export enum CALENDAR_MESSAGES {
  SELECT = 'Select a slot to create a booking'
}

export enum CALENDAR_VIEWS {
  SLOTS = 'SLOTS',
  MULTI_DAY = 'MULTI_DAY',
  ALL = 'ALL',
  MULTI_STAFF = 'MULTI_STAFF',
  MY_SCHEDULE = 'MY_SCHEDULE'
}

export enum CALENDAR_VIEWS_NAMES {
  SLOTS = 'Slots View',
  MULTI_DAY = 'Multi-day View',
  ALL = 'Operations Calendar',
  MULTI_STAFF = 'Staff Calendar',
  MY_SCHEDULE = 'My Calendar'
}

export const CALENDAR_VIEW_LENGTH = {
  [CALENDAR_VIEWS.SLOTS]: 7,
  [CALENDAR_VIEWS.MULTI_DAY]: 7,
  [CALENDAR_VIEWS.MULTI_STAFF]: 1,
  [CALENDAR_VIEWS.ALL]: 7,
  [CALENDAR_VIEWS.MY_SCHEDULE]: 7
};

export const CALENDAR_VIEW_BOOKING_TYPE = {
  [CALENDAR_VIEWS.SLOTS]: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT],
  [CALENDAR_VIEWS.ALL]: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
  [CALENDAR_VIEWS.MULTI_DAY]: [BOOKING_TYPE.MULTI_DAY],
  [CALENDAR_VIEWS.MULTI_STAFF]: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT],
  [CALENDAR_VIEWS.MY_SCHEDULE]: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
};

export const CALENDAR_VIEWS_TO_SHOW = [CALENDAR_VIEWS.MY_SCHEDULE, CALENDAR_VIEWS.MULTI_STAFF, CALENDAR_VIEWS.ALL];
