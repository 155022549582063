import { FieldFunctionOptions, FieldPolicy, makeVar, ReactiveVar } from '@apollo/client';
import AppStorage from '../utils/AppStorage';

export const PERSISTED_VARS_STORAGE_KEY = 'REACTIVE_PERSISTED_VARS';

export function makeVarPersisted<T>(key: string, initialValue: T): ReactiveVar<T> {
  const variable = makeVar<T>(initialValue);
  const keyName = `${PERSISTED_VARS_STORAGE_KEY}/${key}`;

  function handleOnChangeEvent(data: T): void {
    AppStorage.setItem(keyName, JSON.stringify(data));
    variable.onNextChange(handleOnChangeEvent);
  }

  function restore(): void {
    const previousValue = AppStorage.getItem(keyName);
    try {
      if (previousValue) {
        variable(JSON.parse(previousValue));
      }
    } catch (e) {}
  }

  restore();
  variable.onNextChange(handleOnChangeEvent);
  return variable;
}

export type ReactiveFieldValueType = FieldPolicy<unknown, unknown, unknown, FieldFunctionOptions<Record<string, unknown>, Record<string, unknown>>>;
export function generateReactiveFieldValue<T>(reactiveVar: ReactiveVar<T>): ReactiveFieldValueType {
  const reactiveFieldValue: ReactiveFieldValueType = {
    read(): T {
      return reactiveVar();
    }
  };
  return reactiveFieldValue;
}

type GeneratedVars<T> = {
  [K in keyof T]: ReactiveVar<T[K]>;
};

export function makeVarNormal<T>(key: string, initialValue: T): ReactiveVar<T> {
  const variable = makeVar<T>(initialValue);
  const keyName = `${PERSISTED_VARS_STORAGE_KEY}/${key}`;

  function handleOnChangeEvent(data: T): void {
    variable.onNextChange(handleOnChangeEvent);
    // console.log(keyName, data);
  }

  variable.onNextChange(handleOnChangeEvent);
  return variable;
}

export function generateVars<T>(initialStates: T, persistedVars: string[] = []): GeneratedVars<T> {
  const vars = Object.entries(initialStates).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: persistedVars.includes(key) ? makeVarPersisted(key, value) : makeVarNormal(key, value)
    }),
    {} as GeneratedVars<T>
  );
  return vars;
}

export function generateReactiveFields<T>(fields: T): Record<keyof T, ReactiveFieldValueType> {
  return Object.keys(fields).reduce((acc, key) => ({ ...acc, [key]: generateReactiveFieldValue(fields[key]) }), {} as Record<keyof T, ReactiveFieldValueType>);
}
