import * as linkify from 'linkifyjs';
import * as UUID from 'uuid';
import { BUS_USER_ROLES } from '../views/Bookings/types';

export const getKeys = Object.keys as <T extends {}>(obj: T) => Array<Extract<keyof T, string>>;

export type ValueOf<T> = T[keyof T];
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];
export const isUserSuperVisorOrAdminOrSuper = currentBusUserProfile => [BUS_USER_ROLES.ADMIN, BUS_USER_ROLES.SUPERVISOR, BUS_USER_ROLES.SUPER_ADMIN].includes(currentBusUserProfile?.role);
export const isUserSuperVisor = currentBusUserProfile => currentBusUserProfile?.role === BUS_USER_ROLES.SUPERVISOR;
export const isUserSuperAdmin = currentBusUserProfile => currentBusUserProfile?.role === BUS_USER_ROLES.SUPER_ADMIN;

export const randomId = () => Math.random().toString(36).slice(2);
export const randomCode = () => Math.random().toString(36).slice(2).toUpperCase();

export const areObjectsEqual = (object1: { [k: string]: any }, object2: { [k: string]: any }, log?: boolean) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = val1 && val2 && typeof val1 === 'object' && typeof val2 === 'object';
    if ((areObjects && !areObjectsEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
      if (log) {
        console.log('areObjectsEqual', key, val1, val2);
      }

      return false;
    }
  }

  return true;
};

/**
 * Transforms a string to a PascalCase where the first letter of every word is capitalized.
 * Also replaces any dash or underscore with an empty space.
 *
 * @param str The string to be transformed to PascalCase
 */
export const toPascalCase = (str: string) => str.replace(/[-_]+/g, ' ').replace(/(\w)(\w*)/g, (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase());

export const getRandomPetPlaceholder = icons => {
  return icons?.['pet' + Math.ceil(Math.random() * 5)]?.childImageSharp?.gatsbyImageData?.images.fallback.src;
};

export const compareObjectsAndLog = (object1: { [k: string]: any }, object2: { [k: string]: any }) => {
  console.log(areObjectsEqual(object1, object2, true));
  return areObjectsEqual(object1, object2);
};

export const replaceVariables = (text: string, variables: Record<string, string> = {}) => text.replace(/\{\{([^}]+)\}\}/g, (match, variable) => variables[variable] || '');

export const getAllLinksFromText = (text: string) => {
  const linksInMessage = linkify.find(text).map(link => link.href);
  const links =
    linksInMessage?.map(link => {
      try {
        return decodeURIComponent(link);
      } catch (e) {
        return link;
      }
    }) || [];
  return !!linksInMessage.length && links;
};

export const checkIfTextHasLink = (text: string) => {
  const messageHasLink = linkify.test(text);
  return !!messageHasLink;
};

export const getLinkHostname = (link: string) => {
  if (!link) return null;
  const hostname = link.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0];
  return hostname;
};

export const generateUUID = () => {
  return UUID.v4();
};

/**
 * Checks if strings has enters and if so, it returns a maximum of 2 lines
 * @param text
 * @example `This is a text with enters
 *
 *
 *
 * and this is the second line`
 * @returns `This is a text with enters
 *
 *  and this is the second line`
 */

export const formatTextParagraphs = (text: string) => {
  return text
    .trim()
    .replace(/\n{3,}/g, '\n\n')
    .replace(/\n{2,}$/, '\n');
};

export const numberWithCommas = (number?: number | string) => {
  if (!number) return '0';
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

function getRGB(c) {
  return parseInt(c, 16) || c;
}

function getsRGB(c) {
  return getRGB(c) / 255 <= 0.03928 ? getRGB(c) / 255 / 12.92 : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor) {
  return 0.2126 * getsRGB(hexColor.substr(1, 2)) + 0.7152 * getsRGB(hexColor.substr(3, 2)) + 0.0722 * getsRGB(hexColor.substr(-2));
}

function getContrast(f, b) {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function getTextColor(bgColor) {
  const whiteContrast = getContrast(bgColor, '#ffffff');
  const blackContrast = getContrast(bgColor, '#000000');

  return whiteContrast > blackContrast ? '#ffffff' : '#000000';
}

export const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getBirthdateDateFromTo = (birthdate: 'today' | 'inAWeek' | 'inThreeDays') => {
  if (birthdate === 'today') {
    const todayDateFrom = new Date();
    const todayDateTo = new Date();

    const monthFrom = todayDateFrom.getUTCMonth() + 1;
    const monthTo = todayDateTo.getUTCMonth() + 1;
    const dayFrom = todayDateFrom.getUTCDate();
    const dayTo = todayDateTo.getUTCDate();

    return {
      monthFrom,
      monthTo,
      dayFrom,
      dayTo
    };
  }

  if (birthdate === 'inAWeek') {
    const inAWeekDateFrom = new Date();
    const inAWeekDateTo = new Date();

    inAWeekDateTo.setUTCDate(inAWeekDateTo.getUTCDate() + 7);
    const monthFrom = inAWeekDateFrom.getUTCMonth() + 1;
    const monthTo = inAWeekDateTo.getUTCMonth() + 1;
    const dayFrom = inAWeekDateFrom.getUTCDate();
    const dayTo = inAWeekDateTo.getUTCDate();

    return {
      monthFrom,
      monthTo,
      dayFrom,
      dayTo
    };
  }

  if (birthdate === 'inThreeDays') {
    const inThreeDaysDateFrom = new Date();
    const inThreeDaysDateTo = new Date();

    inThreeDaysDateTo.setUTCDate(inThreeDaysDateTo.getUTCDate() + 3);
    const monthFrom = inThreeDaysDateFrom.getUTCMonth() + 1;
    const monthTo = inThreeDaysDateTo.getUTCMonth() + 1;
    const dayFrom = inThreeDaysDateFrom.getUTCDate();
    const dayTo = inThreeDaysDateTo.getUTCDate();

    return {
      monthFrom,
      monthTo,
      dayFrom,
      dayTo
    };
  }
};
