import { store, ReactNotificationOptions } from 'react-notifications-component';
import { generateUUID, wait } from './utils/helpers';

type PropsType = Partial<ReactNotificationOptions> & { dismissIn?: number };

const Notifier = function () {
  const defaultSettings: ReactNotificationOptions = {
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__fadeIn'],
    animationOut: ['animate__fadeOut'],
    dismiss: {
      duration: 3000,
      onScreen: true,
      waitForAnimation: true
    }
  };

  // let actionShown = false;
  // const infoSettings: ReactNotificationOptions = {
  //   insert: 'top',
  //   container: 'top-center',
  //   animationIn: ['animate__slideInDown', 'notification__action'],
  //   animationOut: ['animate__slideOutUp', 'notification__action'],
  //   dismiss: {
  //     duration: 0,
  //     onScreen: true,
  //     waitForAnimation: false,
  //     showIcon: true
  //   },
  //   onRemoval: () => {
  //     actionShown = false;
  //   }
  // };

  const _addNotification = (props: PropsType & { type: ReactNotificationOptions['type'] }) => {
    const id = props.id || generateUUID();
    store.addNotification?.({
      ...defaultSettings,
      ...props,
      animationOut: [...(props.animationOut || defaultSettings.animationOut || []), 'animate__hide'],
      id: id
    });

    wait(props.dismissIn || 3000).then(() => {
      store.removeNotification(id);
    });
  };

  const _error = (props: PropsType) => _addNotification?.({ ...props, type: 'danger' });

  const _success = (props: PropsType) => _addNotification?.({ ...props, type: 'success' });

  const _warn = (props: PropsType) => _addNotification?.({ ...props, type: 'warning' });

  const _info = (props: PropsType) => _addNotification?.({ ...props, type: 'info' });

  const _default = (props: PropsType) => _addNotification?.({ ...props, type: 'default' });

  const _remove = store.removeNotification;

  // const _action = (props: Partial<ReactNotificationOptions>) => {
  //   if (!actionShown) {
  //     actionShown = true;
  //     store.addNotification?.({
  //       ...infoSettings,
  //       ...props,
  //       type: 'info'
  //     });
  //   }
  // };
  return {
    error: _error,
    success: _success,
    warn: _warn,
    info: _info,
    default: _default,
    remove: _remove
    // action: _action
  };
};
export default Notifier();
