import { CALENDAR_MODAL_TITLES, CALENDAR_VIEWS } from '../components/Calendar/types';
import { getMonday } from '../utils/dates';
import { Booking } from '../views/Bookings/types';
import { generateVars } from './utils';

type SelectedDate = {
  date: Date;
  hour: string | number;
  minute?: string | number;
} | null;

const initialStates: {
  selectedDate: SelectedDate;
  modalTitle: CALENDAR_MODAL_TITLES;
  newBookingDefaultAppointments: Booking[];
  calendarMessage: boolean;
  calendarTagCategoryId: string | null;
  calendarDefaultGutterSizes: number[];
  calendarSelectedBookingType: CALENDAR_VIEWS;
  calendarDate: Date;
  calendarLength: number | null;
} = {
  selectedDate: null,
  modalTitle: CALENDAR_MODAL_TITLES.NEW_BOOKING,
  newBookingDefaultAppointments: [],
  calendarMessage: false,
  calendarTagCategoryId: null,
  calendarDefaultGutterSizes: [0, 100],
  calendarSelectedBookingType: CALENDAR_VIEWS.MY_SCHEDULE,
  calendarDate: new Date(getMonday(new Date(new Date().setHours(0, 0, 0, 0)))),
  calendarLength: null
};

export const persistedVarsStorageKeys = ['calendarTagCategoryId', 'calendarDefaultGutterSizes', 'calendarSelectedBookingType', 'calendarDate', 'calendarLength'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
