import { SelectedPetsFilter } from '../components/Shared/Filters/config';
import { generateVars } from './utils';

const initialStates: {
  selectedPetsFilters: SelectedPetsFilter[];
  petsHeaderActions: { id: string; action: JSX.Element }[];
  petSelectedCategoryId: string | null;
} = {
  selectedPetsFilters: [],
  petsHeaderActions: [],
  petSelectedCategoryId: null
};

export const persistedVarsStorageKeys = ['petSelectedCategoryId'];

export const vars = generateVars(initialStates, persistedVarsStorageKeys);
