import React from 'react';
import { errorCodes, errorDetails } from '../../utils/constants/errors';
import { ErrorNotificationContainer } from './Shared';

const ErrorNotification = ({ description, title }: { description?: string; title?: string }) => {
  return (
    <ErrorNotificationContainer>
      {title || errorDetails[errorCodes.UNKNOWN_ERROR].message}
      {description && <span style={{ fontSize: '0.9rem' }}>{description}</span>}
    </ErrorNotificationContainer>
  );
};

export default ErrorNotification;
